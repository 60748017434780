<template>
  <div>
    <field-display
      :loading="loading"
      title="Registration Information"
      :fields="regFields"
      bgColor="grey lighten-4"
    ></field-display>
    <field-display
      v-if="pairFields.length > 0"
      :loading="loading"
      title="Pairs"
      :fields="pairFields"
      bgColor="grey lighten-4"
    ></field-display>
    <field-display
      v-if="squadFields.length > 0"
      :loading="loading"
      title="Squads"
      :fields="squadFields"
      bgColor="grey lighten-4"
    ></field-display>
    <template v-if="refundPolicyIds.length > 0">
      <refund-policy-display
        v-for="p in refundPolicyIds"
        :key="p"
        :refundPolicyId="p"
        :active="true"
        @agree-change-id="onAgreeChange"
      >
      </refund-policy-display>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { usDollars } from '@/Filters'
import FieldDisplay from '@/components/Tournament/Registration/New//FieldDisplay'
const RefundPolicyDisplay = () => import('@/components/Cart/RefundPolicyDisplay.vue')

export default {
  props: ['registration', 'loading', 'active'],
  data () {
    return {
      agreeIds: []
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    teamInfo () {
      return this.registration.teamInfo
    },
    regFields () {
      const r = this.registration.teamInfo
      const c = r && r.contacts && r.contacts[0]
      return r && c && [
        { label: 'Tournament', value: this.tournament.name, show: true },
        // { label: 'Date', value: this.registration.division && this.registration.division.startDate.format('dddd, MMMM Do YYYY'), show: true },
        { label: 'Team', value: r.name, show: true },
        { label: 'Primary Contact', value: c.name, show: true },
        { label: 'Email', value: c.email, show: !c.id },
        { label: 'Phone', value: c.phone, show: !c.id }
      ].filter(f => f.show)
    },
    pairFields () {
      var fields = []
      const pairs = this.registrations.filter(f => f.isPairs)
      if (pairs.length > 0) {
        fields.push({ label: 'Pairs', header: true, show: true })
        pairs.forEach((p, i) => {
          fields.push({ label: `Pair ${i + 1}`, value: p.teamName, show: true })
          fields.push({ label: `Pair ${i + 1} Division`, value: p.division.divisionsString, show: true })
          fields.push({ label: `Pair ${i + 1} Entry Fee`, value: usDollars(p.entryFee), show: true, divide: true })
        })
        if (fields.length > 0) fields[fields.length - 1].divide = false
      }
      return fields
    },
    squadFields () {
      var fields = []
      const squads = this.registrations.filter(f => f.isSquad)
      if (squads.length > 0) {
        fields.push({ label: 'Squads', header: true, show: true })
        squads.forEach((p, i) => {
          fields.push({ label: `Squad ${i + 1}`, value: p.teamName, show: true })
          fields.push({ label: `Squad ${i + 1} Division`, value: p.division.divisionsString, show: true })
          fields.push({ label: `Squad ${i + 1} Entry Fee`, value: usDollars(p.entryFee), show: true, divide: true })
        })
        if (fields.length > 0) fields[fields.length - 1].divide = false
      }
      return fields
    },
    teams () {
      return this.teamInfo && this.teamInfo.teams
    },
    registrations () {
      const r = this.registration.teamInfo
      const c = r && r.contacts && r.contacts[0]
      return r.teams.map(t => {
        const r = this.tournament.newRegistration(t.divisionId)
        r.teamName = t.name
        r.teamId = t.id
        r.teamInfo.contacts.push(c)
        return r
      })
    },
    regDivisions () {
      const r = this.registration.teamInfo
      return r.teams.map(m => this.tournament.divisions.find(f => f.id === m.divisionId))
    },
    refundPolicyIds () {
      return [...new Set(this.regDivisions.map(m => m.refundPolicyId).filter(f => !!f))]
    },
    allAgreed () {
      const a = this.agreeIds
      const b = this.refundPolicyIds
      return a.every(item => b.includes(item)) && b.every(item => a.includes(item))
    }
  },
  methods: {
    onAgreeChange (v) {
      if (v.value) {
        this.agreeIds.push(v.id)
      } else {
        this.agreeIds = this.agreeIds.filter(f => f !== v.id)
      }
    },
    emitAgree () {
      this.$emit('agree-change', this.allAgreed)
    }
  },
  watch: {
    allAgreed: 'emitAgree',
    active: 'emitAgree'
  },
  mounted () {
    this.$nextTick(() => {
      this.emitAgree()
    })
  },
  components: {
    FieldDisplay,
    RefundPolicyDisplay
  }
}
</script>
